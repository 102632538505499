import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
import s2a from './modules/s2a'
import search from './modules/search';
import promotion from './modules/promotion';
import evenement from './modules/evenement';
import videos from './modules/videos';
// Create store
export default new Vuex.Store({
  state: {
    lng : 'Français'
  },
  getters: {
    lng : (state) => state.lng
  },
  mutations: {
    setlng: function(state, {lang}){
        state.lng = lang;
    },
  },
  actions: {
    
  },
  modules: {
    s2a,
    search,
    promotion,
    evenement,
    videos
  },
  plugins: [createPersistedState()]
});
