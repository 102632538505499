import axios from "axios";
import { baseURL } from "../../main";
const state = {
  video: {},
};

const getters = {
  video: (state) => state.video,
};

const actions = {
  async getvideo({ commit }) {
    let response = await axios.get(baseURL + "/sport/s2a/video/top");
    let data = response.data;
    commit("setvideo", data);
  },
};

const mutations = {
  setvideo(state, video) {
    state.video = video;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
