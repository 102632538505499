<template>
  <v-app>
    <div class="" id="">
      <!-- Navbar STart -->
      <Head></Head>
      <!-- Navbar End -->

      <!-- Hero Start -->
      <Banner></Banner>
      <!-- Hero End -->

      <!-- Start Features -->
      <section class="d-flex justify-content-end">
        <div class="me-8">
          <V-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              append-icon="mdi-magnify"
              single-line
              @click:append="searchresult"
              :label="$t('search')"
              hide-details
              @keydown.enter="searchresult"
            >
            </v-text-field>
          </V-toolbar>
        </div>
      </section>
      <!-- <div
        class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light"
      >
        <div class="col-md-5 p-lg-5 mx-auto my-5">
          <img
            src="/lg.png"
            class="l-light img-fluid"
            style=" width: 500px; padding: 10px;"
            alt=""
          />
          <p class="lead fw-normal">
            {{ $t("footer.logodesc") }}
          </p>
        </div>
        <div class="product-device shadow-sm d-none d-md-block"></div>
        <div
          class="product-device product-device-2 shadow-sm d-none d-md-block"
        ></div>
      </div> -->
      <section class="ms-4 me-4 pt-2 pb-6">
        <header class="mb-4 border-bottom text-center">
          <a
            href="#"
            class="d-flex align-items-center text-dark text-decoration-none"
          >
            <span
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="29"
                class="bi bi-stars"
                viewBox="0 0 16 16"
              >
                <path
                  d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z"
                />
              </svg>
              <!-- {{ $t("cat4") }}
              -->
            </span>
          </a>
        </header>
        <div class="row align-items-md-stretch">
          <div
            v-for="img in img4"
            v-bind:key="img.id"
            class="col-md-6 gauche pt-2"
          >
            <!-- <router-link
              v-bind:to="{
                path: `/${$i18n.locale}/article/details/` + img.id,
              }"
            > 
            </router-link>
            -->
            <img style="width: 98%" :src="img.url" alt="" />
            <!-- <video-player :options="playeroption1" class="">
              <source :src="img.url" type="video/mp4" />
            </video-player> -->
            <!-- <h5>{{img.contenu}}</h5> -->
          </div>
          <div
            v-for="img in img5"
            v-bind:key="img.id"
            class="col-md-6 droite pt-2"
          >
            <!-- <router-link
              v-bind:to="{
                path: `/${$i18n.locale}/article/details/` + img.id,
              }"
            >
            </router-link> -->
            <img style="width: 98%" :src="img.url" alt="" />
            <!-- <video-player :options="playeroption2" class="">
              <source :src="img.url" type="video/mp4" />
            </video-player>
            -->
            <!-- <h5>{{img.contenu}}</h5>  -->
          </div>
        </div>
      </section>
      <HomeCard :Homecards="s2a" :Cat="$t('cat1')" />
      <HomeCard :Homecards="nike" :Cat="$t('cat5')" />
      <HomeCard :Homecards="adidas" :Cat="$t('cat2')" />
      <HomeCard :Homecards="puma" :Cat="$t('cat3')" />
      <section id="testimonials" class="testimonials">
        <div class="container position-relative overflow-hidden">
          <div class="section-header">
            <h2>{{ $t("testimonial.testimonail") }}</h2>
            <p>
              {{ $t("testimonial.titre") }}
              <span>{{ $t("testimonial.titre2") }}</span>
            </p>
          </div>
          <div class="slides-1 swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="testimonial-item">
                  <div class="row gy-2 justify-content-center">
                    <div class="col-10 col-md-8 col-sm-2 col-xs-6">
                      <div class="testimonial-content">
                        <p>
                          <i class="bi bi-quote quote-icon-left"></i>
                          {{ $t("testimonial.deuxquatre") }}
                          <i class="bi bi-quote quote-icon-right"></i>
                        </p>
                        <h3>Kagni AMATOUTCHOUI</h3>
                        <h4>International togolais</h4>
                        <div class="stars">
                          <i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-8 col-md-2 col-sm-8 col-xs-6 text-center">
                      <img
                        src="../assets/testimonials/testimonials-1.jpg"
                        class="img-fluid testimonial-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="testimonial-item">
                  <div class="row gy-2 justify-content-center">
                    <div class="col-10 col-md-8 col-sm-2 col-xs-6">
                      <div class="testimonial-content">
                        <p>
                          <i class="bi bi-quote quote-icon-left"></i>
                          {{ $t("testimonial.deuxdeux") }}
                          <i class="bi bi-quote quote-icon-right"></i>
                        </p>
                        <h3>Jack BESSAN</h3>
                        <h4>International beninois</h4>
                        <div class="stars">
                          <i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-8 col-md-2 col-sm-8 col-xs-6 text-center">
                      <img
                        src="../assets/testimonials/testimonials-2.jpg"
                        class="img-fluid testimonial-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="testimonial-item">
                  <div class="row gy-2 justify-content-center">
                    <div class="col-10 col-md-8 col-sm-2 col-xs-6">
                      <div class="testimonial-content">
                        <p>
                          <i class="bi bi-quote quote-icon-left"></i>
                          {{ $t("testimonial.deuxtrois") }}
                          <i class="bi bi-quote quote-icon-right"></i>
                        </p>
                        <h3>Richard NANE</h3>
                        <h4>International Togolais</h4>
                        <div class="stars">
                          <i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-8 col-md-2 col-sm-8 col-xs-6 text-center">
                      <img
                        src="../assets/testimonials/testimonials-3.jpg"
                        class="img-fluid testimonial-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="testimonial-item">
                  <div class="row gy-2 justify-content-center">
                    <div class="col-10 col-md-8 col-sm-2 col-xs-6">
                      <div class="testimonial-content">
                        <p>
                          <i class="bi bi-quote quote-icon-left"></i>
                          {{ $t("testimonial.deux") }}
                          <i class="bi bi-quote quote-icon-right"></i>
                        </p>
                        <h3>HAMIDOU</h3>
                        <h4>{{ $t("testimonial.trois") }}</h4>
                        <div class="stars">
                          <i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-8 col-md-2 col-sm-8 col-xs-6 text-center">
                      <img
                        src="../assets/testimonials/testimonials-5.jpg"
                        class="img-fluid testimonial-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- End testimonial item -->

              <div class="swiper-slide">
                <div class="testimonial-item">
                  <div class="row gy-2 justify-content-center">
                    <div class="col-10 col-md-8 col-sm-6 col-xs-6">
                      <div class="testimonial-content">
                        <p>
                          <i class="bi bi-quote quote-icon-left"></i>
                          {{ $t("testimonial.un") }}
                        </p>
                        <h3>GODWIN</h3>
                        <h4>{{ $t("testimonial.trois") }}</h4>
                        <div class="stars">
                          <i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-8 col-md-2 col-sm-6 col-xs-6 text-center">
                      <img
                        src="../assets/testimonials/testimonials-6.jpg"
                        class="img-fluid testimonial-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="testimonial-item">
                  <div class="row gy-2 justify-content-center">
                    <div class="col-10 col-md-8 col-sm-6 col-xs-6">
                      <div class="testimonial-content">
                        <p>
                          <i class="bi bi-quote quote-icon-left"></i>
                          {{ $t("testimonial.deuxcinq") }}
                        </p>
                        <h3>ARAFAT</h3>
                        <h4>{{ $t("testimonial.cinq") }}</h4>
                        <div class="stars">
                          <i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i
                          ><i class="bi bi-star-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-8 col-md-2 col-sm-6 col-xs-6 text-center">
                      <img
                        src="../assets/testimonials/testimonials-7.jpg"
                        class="img-fluid testimonial-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </section>
      <!-- End Testimonials Section -->
      <!-- Footer Start -->
      <Foot></Foot>
      <!-- Footer End -->
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Foot from "../components/Foot.vue";
import Head from "../components/Head.vue";
import Banner from "../components/Banner.vue";
import HomeCard from "../components/HomeCard.vue";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import i18n from "../i18n";
export default {
  name: "Home",
  components: {
    Foot,
    Head,
    Banner,
    HomeCard,
    videoPlayer,
  },
  data: () => {
    return {
      model1: null,
      image: {},
      currentpage: 1,
      catmaillot: "",
      search: [],
      img: [],
      img2: [],
      img3: [],
      img4: [],
      img5: [],
      img6: [],
      playeroption1: {
        autoplay: true,
        height: "380",
        muted: true,
        loop: true,
        controls: false,
        responsive: true,
        preload: "true",
        language: "fr",
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        breakpoints: {
          tiny: 300,
          xsmall: 400,
          small: 500,
          medium: 600,
          large: 700,
          xlarge: 800,
          huge: 900,
        },
      },
      playeroption2: {
        autoplay: true,
        height: "380",
        muted: true,
        loop: true,
        preload: "true",
        controls: false,
        language: "fr",
        responsive: true,
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        breakpoints: {
          tiny: 300,
          xsmall: 400,
          small: 500,
          medium: 600,
          large: 700,
          xlarge: 800,
          huge: 900,
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      nikeshoes: "s2aarticlesnike",
      adidasshoes: "s2aarticlesadidas",
      pumashoes: "s2aarticlespuma",
      ensemble: "s2aarticlesensemble",
      fitness: "s2aarticlesfitness",
      autres: "s2aarticlesautre",
      survet: "s2aarticlessurvet",
      searchs: "search",
      videos: "video",
    }),
    nike() {
      if (Array.isArray(this.nikeshoes)) {
        const firstFourAlbums = this.nikeshoes.slice(0, 4).map((element) => {
          const modifiedAlbums = {
            id: element.albums.idArticle,
            url: element.albums.photo_entete,
            designation: element.albums.designation,
            enstock: element.albums.enstock,
            prix: element.albums.prix,
            devise: element.albums.devise.libelle,
            promotion: element.albums.promotion,
            pourcentage: element.albums.pourcentage,
            cat: element.albums.categorie.libelle,
          };
          return modifiedAlbums;
        });
        return firstFourAlbums;
      } else {
        return "";
      }
    },
    adidas() {
      if (Array.isArray(this.adidasshoes)) {
        const firstFourAlbums = this.adidasshoes.slice(0, 4).map((element) => {
          const modifiedAlbums = {
            id: element.albums.idArticle,
            url: element.albums.photo_entete,
            designation: element.albums.designation,
            enstock: element.albums.enstock,
            prix: element.albums.prix,
            devise: element.albums.devise.libelle,
            promotion: element.albums.promotion,
            pourcentage: element.albums.pourcentage,
            cat: element.albums.categorie.libelle,
          };
          return modifiedAlbums;
        });
        return firstFourAlbums;
      } else {
        return "";
      }
    },
    puma() {
      if (Array.isArray(this.pumashoes)) {
        const firstFourAlbums = this.pumashoes.slice(0, 4).map((element) => {
          const modifiedAlbums = {
            id: element.albums.idArticle,
            url: element.albums.photo_entete,
            designation: element.albums.designation,
            enstock: element.albums.enstock,
            prix: element.albums.prix,
            devise: element.albums.devise.libelle,
            promotion: element.albums.promotion,
            pourcentage: element.albums.pourcentage,
            cat: element.albums.categorie.libelle,
          };
          return modifiedAlbums;
        });
        return firstFourAlbums;
      } else {
        return "";
      }
    },
    s2a() {
      if (Array.isArray(this.autres) && Array.isArray(this.ensemble) && Array.isArray(this.survet) && Array.isArray(this.fitness) ) {
        const [first] = this.autres.slice(0, 1);
        const [second] = this.ensemble.slice(0, 1);
        const [third] = this.survet.slice(0, 1);
        const [four] = this.fitness.slice(0, 1);

        const formattedfirst = {
          id: first.albums.idArticle,
          url: first.albums.photo_entete,
          designation: first.albums.designation,
          enstock: first.albums.enstock,
          prix: first.albums.prix,
          devise: first.albums.devise.libelle,
          promotion: first.albums.promotion,
          pourcentage: first.albums.pourcentage,
          cat: first.albums.categorie.libelle,
        };
        const formattedsecond = {
          id: second.albums.idArticle,
          url: second.albums.photo_entete,
          designation: second.albums.designation,
          enstock: second.albums.enstock,
          prix: second.albums.prix,
          devise: second.albums.devise.libelle,
          promotion: second.albums.promotion,
          pourcentage: second.albums.pourcentage,
          cat: second.albums.categorie.libelle,
        };
        const formattedthird = {
          id: third.albums.idArticle,
          url: third.albums.photo_entete,
          designation: third.albums.designation,
          enstock: third.albums.enstock,
          prix: third.albums.prix,
          devise: third.albums.devise.libelle,
          promotion: third.albums.promotion,
          pourcentage: third.albums.pourcentage,
          cat: third.albums.categorie.libelle,
        };
        const formattedfour = {
          id: four.albums.idArticle,
          url: four.albums.photo_entete,
          designation: four.albums.designation,
          enstock: four.albums.enstock,
          prix: four.albums.prix,
          devise: four.albums.devise.libelle,
          promotion: four.albums.promotion,
          pourcentage: four.albums.pourcentage,
          cat: four.albums.categorie.libelle,
        };
        const allformated = [
          formattedfirst,
          formattedsecond,
          formattedthird,
          formattedfour,
        ];
        return allformated;
      } else {
        return "";
      }
    },
  },

  mounted() {
    this.$store.dispatch("gets2aarticlesensemble", {
      id: 2,
      page: this.currentpage,
    });
    this.$store.dispatch("gets2aarticlesfitness", {
      id: 4,
      page: this.currentpage,
    });
    this.$store.dispatch("gets2aarticlesautre", {
      id: 74,
      page: this.currentpage,
    });
    this.$store.dispatch("gets2aarticlessurvet", {
      id: 3,
      page: this.currentpage,
    });
    this.$store.dispatch("gets2aarticlesnike", {
      id: 5,
      page: this.currentpage,
    });
    this.$store.dispatch("gets2aarticlesadidas", {
      id: 6,
      page: this.currentpage,
    });
    this.$store.dispatch("gets2aarticlespuma", {
      id: 11,
      page: this.currentpage,
    });
    // this.playeroption1.sources[0].src = this.img5[0].url;
    // this.playeroption2.sources[0].src = this.img4[0].url;
  },
  methods: {
    setImage(image) {
      this.image = image;
    },
    searchresult() {
      if (this.search) {
        this.$store.dispatch("getsearch", {
          page: this.currentpage,
          s: this.search,
        });
        if (this.searchs.length > 0) {
          this.$router
            .push(`/${i18n.locale}/article/search/` + this.search)
            .catch(() => {});
        }
      }
    },
    getfirst() {
      if (this.videos || this.maillots.videos > 0) {
        if (this.videos[1].video && this.videos[0].video) {
          const j = {
            id: this.videos[1].id,
            url: this.videos[1].video,
            contenu: this.videos[1].contenu,
          };
          const i = {
            id: this.videos[0].id,
            url: this.videos[0].video,
            contenu: this.videos[0].contenu,
          };
          //const vid = {};
          this.img5.push(i);
          this.img4.push(j);
        }
      } else {
        this.img5 = "";
        this.img4 = "";
      }
    },
    // oanke(): function() {
    //   window.open("https://oanke.com/fr");
    // },
    // shopmanager: function() {
    //   window.open("http://shops-manager.com/");
    // },
    // changedtofr: function() {
    //   i18n.locale = "fr";
    //   this.$store.commit("setlng", { lang: this.$t("lang1") });

    //   let language = this.$route.params.lang;
    //   if (language === "en") {
    //     let splitted = this.$route.path.split(`${language}`);
    //     let url = "/fr" + splitted[1];
    //     this.$router.push(url);
    //   }
    // },
    // changedtoen: function() {
    //   i18n.locale = "en";
    //   this.$store.commit("setlng", { lang: this.$t("lang2") });

    //   let language = this.$route.params.lang;
    //   if (language != "en") {
    //     let splitted = this.$route.path.split(`${language}`);
    //     let url = "/en" + splitted[1];
    //     this.$router.push(url);
    //   }
    // },
    toggleMenu: function() {
      document.getElementById("isToggle").classList.toggle("open");
      var isOpen = document.getElementById("navigation");
      if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
      } else {
        isOpen.style.display = "block";
      }
    },
  },
};
</script>

<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.stock {
  padding-top: 50%;
}
.colors {
  color: white;
  background-color: #048444;
}
.stock {
  padding-top: 50%;
}
.colors2 {
  color: white;
  background-color: #fb9f06;
}
.colors3 {
  color: #fbe3d1;
  background-color: #6a0000;
}
.colorrs {
  background-color: #061ee4;
}
.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}

.gauche {
  display: flex;
  justify-content: center;
  align-items: center;
}
.droite {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    flex: 1;
  }
}

section {
  overflow: hidden;
  padding: 19px 0;
}
.section-header {
  text-align: center;
  padding-bottom: 30px;
}

.section-header h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  position: relative;
  color: #061ee4;
  text-transform: uppercase;
}
.section-header p {
  margin: 0;
  font-size: 48px;
  font-weight: 400;
  font-family: "Amatic SC", sans-serif;
}
.section-header h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.section-header h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #ce1212;
  bottom: 0;
  left: calc(50% - 20px);
}
.card-size {
  width: 100%;
  height: 100%;
}
.section-header p span {
  color: #ce1212;
}
.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item .testimonial-content {
  border-left: 3px solid #ce1212;
  padding-left: 30px;
}

.testimonials .testimonial-item .testimonial-img {
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #212529;
  font-family: "Inter", sans-serif;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #6c757d;
  margin: 0 0 10px 0;
  font-family: "Inter", sans-serif;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #f05656;
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
}

.testimonials .swiper-pagination {
  margin-top: 40px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #d1d1d7;
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #061ee4;
}
@media (min-width: 601px) and (max-width: 960px) {
  .stock {
    padding-top: 70% !important;
  }
}
</style>
