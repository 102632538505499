<template>
    <main>
        <v-row no-gutters class="ms-8 me-8">
        <v-col :cols="12">
          <v-card-text class="" tile outlined>
            <v-card-title class="subheading">{{ Cat }}</v-card-title>
            <v-divider></v-divider>
            <div class="row">
              <div
                class="col-12 col-md-3 col-sm-6 col-xs-6 text-center"
                v-for="im in Homecards"
                v-bind:key="im.id"
              >
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-card :elevation="hover ? 16 : 2" class="card-size">
                    <router-link
                      v-bind:to="{
                        path: `/${$i18n.locale}/article/details/` + im.id,
                      }"
                    >
                      <v-img
                        contain
                        class="black--text align-end"
                        height="350px"
                        :src="im.url"
                      >
                        <div class=" align-top d-flex justify-content-end">
                          <template v-if="im.enstock === 'En stock'">
                            <span class="mt-2 me-2 badge badge-pill colors">{{
                              im.enstock
                            }}</span>
                          </template>
                          <template v-else>
                            <span class="mt-2 me-2 badge badge-pill colors2">{{
                              im.enstock
                            }}</span>
                          </template>
                        </div>
                        <div class="align-top d-flex justify-content-end">
                          <template v-if="im.promotion">
                            <span v-if="isBefore(img.albums.dateFinPromo) && !isBefore(img.albums.dateDebutPromo)"
                              class="mt-2 me-2 badge badge-pill colors3"
                              >Promo -{{ im.pourcentage }} %</span
                            >
                          </template>
                        </div>
                      </v-img>
                    </router-link>
                    <v-card-text class="text--primary">
                      <v-card-title class="text-center"
                        >{{ im.designation }}
                      </v-card-title>
                      <div>
                        {{ $t("nike.prix") }} {{ im.prix }} {{ im.devise }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </div>
            </div>
          </v-card-text>
        </v-col>
        <v-divider></v-divider>
      </v-row>
    </main>
</template>
<script>
import { isBefore } from '../helper';
export default {
    props:{
        Homecards:Array,
        Cat:"",
    },
    methods: {
      isBefore(str) {
        if (str) {
          console.log(isBefore(str));
          return isBefore(str);
        } else {
          return false;
        }
    },
  }
}
</script>
<style scoped>
.colors {
  color: white;
  background-color: #048444;
}
.card-size {
  width: 100%;
  height: 100%;
}
.stock {
  padding-top: 50%;
}
.colors2 {
  color: white;
  background-color: #fb9f06;
}
.colors3 {
  color: #fbe3d1;
  background-color: #6a0000;
}
.colorrs {
  background-color: #061ee4;
}
</style>