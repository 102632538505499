import moment from "moment";

export function dayBeetwen1(start, end) {
    var st = moment(start, "YYYY-MM-DD");
    var en = moment(end, "YYYY-MM-DD");

    return moment.duration(st.diff(en)).asDays();
}

export function dayBeetwen(end) {
    // var st = moment(start, "YYYY-MM-DD");
    var current = moment().startOf('day');
    var en = moment(end, "YYYY-MM-DD");

    return moment.duration(en.diff(current)).asDays();
}

export function isBefore(end) {
    const result = moment(new Date()).isBefore(moment(end, "YYYY-MM-DD"));
    return result;
}