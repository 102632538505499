import axios from "axios";
import { baseURL } from "../../main";
const state = {
  evenement:{},
  oneevenement:{}
};

const getters = {
  evenement: (state) => state.evenement,
  oneevenement: (state) => state.oneevenement
};

const actions = {
  async getevenement({ commit },page) {
    let response = await axios.get(
      baseURL + "/sport/s2a/evenements/page/"+page
    );
    let data = response.data;
    commit("setevenement",  data );
  },
  async getoneevenement({ commit },id) {
    let response = await axios.get(
      baseURL + "/sport/s2a/evenement/"+id
    );
    let data = response.data;
    commit("setoneevenement",  data );
  },

  
};

const mutations = {
  setevenement(state, evenement ) {
    state.evenement = evenement;
  },
  setoneevenement(state, oneevenement ) {
    state.oneevenement = oneevenement;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
