<template>
  <main>
    <Head></Head>

    <section class="jumbo">
      <div class="bg-light">
        <div class="col-md-5 p-lg-12 mx-auto ">
          <h2>
            {{ $t("evenement.evenement") }}
          </h2>
        </div>
      </div>
    </section>
    <section class="container">
      <div class="row">
        <div
          v-for="img in event"
          :key="img.id"
          class="col-md-4 col-sm-12 col-xs-8 text-center "
        >
         
            <router-link
              v-bind:to="{
                path: `/${$i18n.locale}/evenement/details/` + img.id,
              }"
            >
              <img
                class="align-end"
                :src="img.pieces[0].url"
                width="350"
                height="260"
              />
            </router-link>
            <p class="card-text font text-center">{{ img.nom }}</p>
        </div>
      </div>
    </section>
    <section class="jumbo pb-2">
      <h2>
        {{ $t("evenement.apropo") }}
      </h2>
      <div class="container ">
        <p class="mb-2 font propos">{{ $t("evenement.apropos") }}</p>
        <p class="font">{{$t("evenement.slogan")}}</p>
      </div>
    </section>
    <Foot></Foot>
  </main>
</template>
<script>
import Head from "../components/Head.vue";
import Foot from "../components/Foot.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseURL } from "../main";
export default {
  name: "evenemnt",
  components: {
    Head,
    Foot,
  },
  data: () => {
    return {
      currentpage: 1,
      alb: [],
    };
  },
  computed: {
    ...mapGetters(["evenement"]),

    event() {
      if (this.evenement) {
        return this.evenement;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.$store.dispatch("getevenement", this.currentpage);
    console.log(this.evenement)
    this.scroll();
  },
  methods: {
    ...mapActions(["getevenement"]),

    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.currentpage = this.currentpage + 1;
          this.scrollinfini(this.currentpage);
          const glightbox = GLightbox({
            selector: ".glightbox",
          });
        }
      };
    },
    async scrollinfini(page) {
      await axios
        .get(baseURL + "/sport/s2a/evenements/page/" + page)
        .then((response) => {
          if (response.data) {
            if (response.data.length > 0) {
              this.getentete(response.data);
              //  this.currentpage=1
            }
          }
        });
    },
    getentete(S2a) {
      for (let i = 0; i < S2a.length; i++) {
        const element = S2a[i];
        this.event.push(element);
      }
    },
    
  },
};
</script>
<style scoped>
.font 
{
  /* font-family: 'Cinzel', serif; */
  font-family: "Nunito", sans-serif;
  font-weight: 100 !important;
}
.jumbo h2 {
  font-size: 32px;
 
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #d9232d;
}
.jumbo h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.jumbo h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #061ee4;
  bottom: 0;
  left: calc(50% - 20px);
}
.apropos {
  text-align: justify;
  height: auto;
  letter-spacing: normal;
  line-height: 1.5;
  word-spacing: 0px;
  padding: 0px;
  font-style: normal;
  font-size: 1rem;
}
.jumbo {
  text-align: center;
  padding-top: 115px;
  margin: 0 60px 0 60px;
}</style>
