import axios from "axios";
import { baseURL } from "../../main";
const state = {
  search:{},
};

const getters = {
  search: (state) => state.search,
};

const actions = {
  async getsearch({ commit },{page,s}) {
    let response = await axios.get(
      baseURL + "/sport/s2a/articles/search/page/"+page+"/"+s 
    );
    let data = response.data;
    commit("setsearch",  data );
  },
  
};

const mutations = {
  setsearch(state, search ) {
    state.search = search;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
