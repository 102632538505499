<template>
  <v-app>
   
      <!-- Navbar STart -->
      <Head></Head>
      <!-- Navbar End -->
      <Detail 
      :details="details">
      </Detail>
      
      <Foot></Foot>
  
  </v-app>
</template>
<script>
import Head from "../components/Head.vue";
import Foot from "../components/Foot.vue";
import Detail from "../components/Detail.vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "Soccershoesdetails",
  components: {
    Head,
    Foot,
    Detail
},
  data: () => {
    return {
      Details: {},  
      editedItem: {
        boutique: JSON.parse(window.localStorage.getItem('boutiqueId')),
        nom: "",
        prenom: "",
        telephone: "",
        adresse: "",
      },
    };
  },
  computed: {
    ...mapGetters(["s2adetails"]),
    Id: function() {
      if (this.$route.params.id) {
        return this.$route.params.id;
      }
    },
    details()
    {
      if(this.s2adetails)
      {
        return this.s2adetails
      }
      else
      {
        return ""
      }
    }
  },
  mounted() {
    this.$store.dispatch("gets2adetails", this.Id);
  },
   created() {
    //this.getfichier()
  },
  methods: {
    ...mapActions(["gets2adetails"]),

    /* 
    Autre manniere de faire 
    getfichier() {  
      for (let i = 0; i < this.nikedetails.fichiers.length; i++) {
        const element = this.nikedetails.fichiers[i];
        const j = {
          // id: element.albums.idArticle,
          url: element.name,
          type: element.type
          // designation: element.albums.designation,
          // enstock: element.albums.enstock,
          // prix: element.albums.prix,
        };
        if(j.type==1)
        {
          // console.log(j.url)
          this.img.push(j);
        }
      }
    },
    */
  },
};
</script>

