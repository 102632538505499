import axios from "axios";
import { baseURL } from "../../main";
const state = {
  s2aarticles:{},
  s2aarticlesnike:{},
  s2aarticlesadidas:{},
  s2aarticlespuma:{},
  s2aarticlesensemble:{},
  s2aarticlesfitness:{},
  s2aarticlesautre:{},
  s2aarticlessurvet:{},
  s2aarticlesdetails:{},
  s2amenu:{},
  s2anikemenu:{},
  s2aothermenu:{},
  s2adetails:{},
  s2amaillots:{},
  s2aculottes:{},
  s2atraining:{},
  s2agadgets:{},
 
};

const getters = {
  s2aarticles: (state) => state.s2aarticles,
  s2aarticlesnike: (state) => state.s2aarticlesnike,
  s2aarticlesadidas: (state) => state.s2aarticlesadidas,
  s2aarticlespuma: (state) => state.s2aarticlespuma,
  s2aarticlesensemble: (state) => state.s2aarticlesensemble,
  s2aarticlesfitness: (state) => state.s2aarticlesfitness,
  s2aarticlesautre: (state) => state.s2aarticlesautre,
  s2aarticlessurvet: (state) => state.s2aarticlessurvet,
  s2aarticlesdetails: (state) => state.s2aarticlesdetails,
  s2amenu: (state) => state.s2amenu,
  s2anikemenu: (state) => state.s2anikemenu,
  s2aothermenu: (state) => state.s2aothermenu,
  s2adetails:(state) =>state.s2adetails,
  s2amaillots:(state) =>state.s2amaillots,
  s2aculottes:(state) =>state.s2aculottes,
  s2atraining:(state) =>state.s2atraining,
  s2agadgets:(state) =>state.s2agadgets,
 
};

const actions = {
  async gets2aarticles({commit},{id,page})
  {
    let response = await axios.get(
      baseURL + "/sport/s2a/articles/categorie/"+ id +"/"+ page
    )
    let data = response.data
    commit("sets2aarticles", data)
  },
  async gets2aarticlesdetails({ commit }, id) {
    let response = await axios.get(
      baseURL + "/sport/articles/" + id
    );
    let data = response.data;
    // console.log(data)
    commit("sets2aarticlesdetails",  data );
  },
  async gets2aarticlesnike({commit},{id,page})
  {
    let response = await axios.get(
      baseURL + "/sport/s2a/articles/categorie/"+ id +"/"+ page
    )
    let data = response.data
    commit("sets2aarticlesnike", data)
  },
  async gets2aarticlesadidas({commit},{id,page})
  {
    let response = await axios.get(
      baseURL + "/sport/s2a/articles/categorie/"+ id +"/"+ page
    )
    let data = response.data
    commit("sets2aarticlesadidas", data)
  },
  async gets2aarticlespuma({commit},{id,page})
  {
    let response = await axios.get(
      baseURL + "/sport/s2a/articles/categorie/"+ id +"/"+ page
    )
    let data = response.data
    commit("sets2aarticlespuma", data)
  },
  async gets2aarticlesensemble({commit},{id,page})
  {
    let response = await axios.get(
      baseURL + "/sport/s2a/articles/categorie/"+ id +"/"+ page
    )
    let data = response.data
    commit("sets2aarticlesensemble", data)
  },
  async gets2aarticlesfitness({commit},{id,page})
  {
    let response = await axios.get(
      baseURL + "/sport/s2a/articles/categorie/"+ id +"/"+ page
    )
    let data = response.data
    commit("sets2aarticlesfitness", data)
  },
  async gets2aarticlesautre({commit},{id,page})
  {
    let response = await axios.get(
      baseURL + "/sport/s2a/articles/categorie/"+ id +"/"+ page
    )
    let data = response.data
    commit("sets2aarticlesautre", data)
  },
  async gets2aarticlessurvet({commit},{id,page})
  {
    let response = await axios.get(
      baseURL + "/sport/s2a/articles/categorie/"+ id +"/"+ page
    )
    let data = response.data
    commit("sets2aarticlessurvet", data)
  },

  async gets2amenu({commit}){ 

    let response = await axios.get(
      baseURL + "/sport/categories/s2a/equipement"
    )
    let data = response.data
    commit("sets2amenu", data)
  },
  async gets2anikemenu({commit},)
  {
    let response = await axios.get(
      baseURL + "/sport/categories/s2a/outils"
    )
    let data = response.data
    commit("sets2anikemenu", data)
  },
  async gets2aothermenu({commit},)
  {
    let response = await axios.get(
      baseURL + "/sport/categories/s2a/autre"
    )
    let data = response.data
    commit("sets2aothermenu", data)
  },
  async gets2adetails({ commit }, id) {
    let response = await axios.get(
      baseURL + "/sport/articles/" + id
    );
    let data = response.data;
    commit("sets2adetails",  data );
  },
  // async gets2amaillots({ commit }, page) {
  //   let response = await axios.get(
  //     baseURL + "/sport/s2a/articles/maillots/complet/" + page
  //   );
  //   let data = response.data;
  //   commit("sets2amaillots",  data );
  // },
  // async gets2aculottes({ commit }, id) {
  //   let response = await axios.get(
  //     baseURL + "/sport/s2a/articles/tops/culottes/" + id
  //   );
  //   let data = response.data;
  //   commit("sets2aculottes",  data );
  // },
  // async gets2atraining({ commit }, id) {
  //   let response = await axios.get(
  //     baseURL + "/sport/s2a/articles/fitness/training/" + id
  //   );
  //   let data = response.data;
  //   commit("sets2atraining",  data );
  // },
  // async gets2agadgets({ commit }, page) {
  //   let response = await axios.get(
  //     baseURL + "/sport/s2a/articles/chaussures/gadgets/" + page
  //   );
  //   let data = response.data;
  //   commit("sets2agadgets",  data );
  // },
};

const mutations = {
  sets2aarticles(state, s2aarticles ) {
    state.s2aarticles = s2aarticles;
  },
  sets2aarticlesdetails(state, s2aarticlesdetails ) {
    state.s2aarticlesdetails = s2aarticlesdetails;
  },
  sets2aarticlesnike(state, s2aarticlesnike ) {
    state.s2aarticlesnike = s2aarticlesnike;
  },
  sets2aarticlesadidas(state, s2aarticlesadidas ) {
    state.s2aarticlesadidas = s2aarticlesadidas;
  },
  sets2aarticlespuma(state, s2aarticlespuma ) {
    state.s2aarticlespuma = s2aarticlespuma;
  },
  sets2aarticlesensemble(state, s2aarticlesensemble ) {
    state.s2aarticlesensemble = s2aarticlesensemble;
  },
  sets2aarticlesfitness(state, s2aarticlesfitness ) {
    state.s2aarticlesfitness = s2aarticlesfitness;
  },
  sets2aarticlesautre(state, s2aarticlesautre ) {
    state.s2aarticlesautre = s2aarticlesautre;
  },
  sets2aarticlessurvet(state, s2aarticlessurvet ) {
    state.s2aarticlessurvet = s2aarticlessurvet;
  },
  sets2amenu(state, s2amenu ) {
    state.s2amenu = s2amenu;
  },
  sets2anikemenu(state, s2anikemenu ) {
    state.s2anikemenu = s2anikemenu;
  },
  sets2aothermenu(state, s2aothermenu ) {
    state.s2aothermenu = s2aothermenu;
  },
  sets2adetails(state, s2adetails ) {
    state.s2adetails = s2adetails;
  },
  sets2amaillots(state, s2amaillots ) {
    state.s2amaillots = s2amaillots;
  },
  sets2aculottes(state, s2aculottes ) {
    state.s2aculottes = s2aculottes;
  },
  sets2atraining(state, s2atraining ) {
    state.s2atraining = s2atraining;
  },
  sets2agadgets(state, s2agadgets ) {
    state.s2agadgets = s2agadgets;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
