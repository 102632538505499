import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import i18n from './i18n'
import './assets/main.js'
import VueMoment from 'vue-moment';

const newLocal ="https://shopymanager.com:8443/s2a-catalogue-dev-1/api/auth"
//const newLocal = 'http://192.168.0.114:8080/s2a-catalogue-dev/api/auth';
// const newLocal = 'http://localhost:9191/api/auth';
export const baseURL = newLocal
axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
  
        originalRequest._retry = true;
        store.dispatch('LogOut')
        return router.push({ name: 'Login'})
    }
  }
})


// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = 'fr'
  }

  // set the current language for i18n.
  i18n.locale = language
  next()
})

Vue.config.productionTip = false

Vue.use(VueMoment)


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
