<template>
  <main>
    <section class="jumbo">
      <div class="bg-light">
        <div class="col-md-5 p-lg-12 mx-auto ">
          <h2 v-if="cards[0]">
            {{ cards[0].albums.categorie.libelle }}
          </h2>
          <h1 v-else class="display-5 fw-normal text-dark"></h1>
        </div>
      </div>
    </section>
    <section class="ms-12 me-12">
      <v-row no-gutters>
        <v-col :cols="12">
          <v-card-text class="" tile outlined>
            <div class="row">
              <div
                class="col-12 col-md-4 col-sm-6 col-xs-6 text-center"
                v-for="img in cards"
                v-bind:key="img.albums.idArticle"
              >
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-card :elevation="hover ? 16 : 2" class="card-size">
                    <router-link
                      v-bind:to="{
                        path:
                          `/${$i18n.locale}/article/details/` +
                          img.albums.idArticle,
                      }"
                    >
                      <div class="cards">
                        <v-img
                          contain
                          class="white--text "
                          height="380px"
                          :src="img.albums.photo_entete"
                        >
                          <div class=" align-top d-flex justify-content-end">
                            <template v-if="img.albums.enstock === 'En stock'">
                              <span class="mt-2 me-2 badge badge-pill colors">{{
                                img.albums.enstock
                              }}</span>
                            </template>
                            <template v-else>
                              <span
                                class="mt-2 me-2 badge badge-pill colors2"
                                >{{ img.albums.enstock }}</span
                              >
                            </template>
                          </div>
                          <div class="align-top d-flex justify-content-end">
                            <template v-if="img.albums.promotion">
                              <span 
                              v-if="isBefore(img.albums.dateFinPromo) && !isBefore(img.albums.dateDebutPromo)"
                               class="mt-2 me-2 badge badge-pill colors3"
                                >Promo -{{ img.albums.pourcentage }} %</span
                              >
                            </template>
                          </div>
                        </v-img>
                      </div>
                    </router-link>
                    <v-card-text class="text-center">
                      <v-card-title class="text-center">
                        {{ img.albums.designation }}
                      </v-card-title>
                      <div>
                        {{ $t("nike.prix") }} {{ img.albums.prix }} {{ img.albums.devise.libelle }}
                      </div>
                    </v-card-text>
                    <!-- <div class="stock">
                          </div> -->

                    <!-- <v-card-text class="text--primary text-center">
                        <div>{{ $t("nike.disponible") }} {{ img.albums.enstock }}</div> 
                        <div>{{ $t("nike.prix") }} {{ img.albums.prix }} FCFA</div> 
                      </v-card-text> -->
                  </v-card>
                </v-hover>
              </div>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </section>
  </main>
</template>
<script>
import { isBefore } from '../helper';
export default {
  props: {
    cards: Array,
  },

  methods: {
    isBefore(str) {
      if (str) {
        console.log(isBefore(str));
        return isBefore(str);
      } else {
        return false;
      }
    },
  }
};
</script>
<style scoped>
.jumbo {
  text-align: center;
  padding-top: 115px;
  margin: 0 60px 0 60px;
}
.colors {
  color: white;
  background-color: #048444;
}
.card-size {
  width: 100%;
  height: 100%;
}
.stock {
  padding-top: 50%;
}
.colors2 {
  color: white;
  background-color: #fb9f06;
}
.colors3 {
  color: #fbe3d1;
  background-color: #6a0000;
}
.colorrs {
  background-color: #061ee4;
}
.principal {
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
}
.jumbo h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #d9232d;
}
.jumbo h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.jumbo h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #061ee4;
  bottom: 0;
  left: calc(50% - 20px);
}
@media (max-width: 600px) {
  .v-image {
    max-width: 100% !important;
    height: auto !important;
  }
}
@media (min-width: 601px) and (max-width: 960px) {
  .stock {
    padding-top: 70% !important;
  }
}
</style>
