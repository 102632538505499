<template>
  <main>
    <section class="jumbo">
      <div class="bg-light">
        <div class="col-md-5 p-lg-12 mx-auto ">
          <h2>
            {{ details.albums.categorie.libelle }}
          </h2>
        </div>
      </div>
    </section>

    <div class="container ">
      <div class="row">
        <div class="col-md-8">
          <v-card-text class="" tile outlined>
            <v-divider></v-divider>
            <div class="row">
              <div
                class="col-12 col-md-6 col-sm-6 col-xs-4 text-center"
                v-for="img in details.fichiers"
                v-bind:key="img.id"
              >
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-card :elevation="hover ? 16 : 2">
                    <a
                      class="glightbox"
                      data-gallery="images-gallery"
                      :href="img.name"
                    >
                      <v-img
                        class="img-fluid white--text align-end"
                        :src="img.name"
                      >
                      </v-img>
                    </a>
                  </v-card>
                </v-hover>
              </div>
            </div>
          </v-card-text>
        </div>
        <div class="col-md-4">
          <v-card-text class="" tile outlined>
            <v-divider></v-divider>
            <div class="row row-cols-1 row-cols-md-3 mb-3 text-center ">
              <div class="col">
                <div class="card mb-4 rounded-3 shadow-sm ">
                  <div class="card-header py-3">
                    <h4 class="my-0 fw-normal">
                      {{ details.albums.designation }}
                    </h4>
                  </div>
                  <div class="card-body ">
                    <p class="description">
                      {{ details.albums.description }}
                    </p>
                    <hr />
                    <p class="prix">
                      {{ $t("nike.prix") }} {{ details.albums.prix }}
                      {{ details.albums.devise.libelle }}
                    </p>
                    <p>
                      {{ $t("promotion.pourcentage") }} :
                      -{{ details.albums.pourcentage }}%
                    </p>
                    <v-btn
                      outlined
                      color="#529033"
                      :href="
                        'https://oanke.com/fr/albums/' +
                          details.albums.idArticle
                      "
                      >{{ $t("commande") }}</v-btn
                    >
                    <template v-if="details.albums.pourcentage"> </template>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  props: {
    details: Object,
  },
};
</script>
<style scoped>
.description {
  text-align: justify;
}
.prix {
  font-size: 150%;
}
.jumbo {
  text-align: center;
  padding-top: 115px;
  margin: 0 23rem 0 23rem;
}
.jumbo h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #d9232d;
}
.jumbo h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.jumbo h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #061ee4;
  bottom: 0;
  left: calc(50% - 20px);
}
.principal {
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
}

@media (max-width: 1889px) {
  .jumbo {
    text-align: center;
    padding-top: 115px;
    margin: 0 0 0 0;
  }
}
</style>
