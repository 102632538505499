<template>
  <div>
    <header id="topnav" class="defaultscroll sticky">
      <div class="container">
        <!-- Logo container-->
        <router-link :to="{ path: `/${$i18n.locale}` }" class="logo">
          <span class="logo-light-mode">
            <img
              src="/lg.png"
              class="l-light"
              style=" width: 100px"
              alt=""
            />
          </span>
        </router-link>
        <!--end login button-->
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a class="navbar-toggle" id="isToggle" @click="toggleMenu()">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul class="navigation-menu head">
            <li>
              <router-link
                :to="{ path: `/${$i18n.locale}` }"
                class="sub-menu-item"
                >{{ $t("nav.acceuil") }}</router-link
              >
            </li>
            <li class="has-submenu parent-menu-item">
              <a>{{ $t("nav.s2a.s2a") }}</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">
                <li v-for="menu in S2a" v-bind:key="menu.idCategorie">
                  <router-link
                  @click.native="ici"
                    :to="{
                      path:
                        `/${$i18n.locale}/article/categorie/` +
                        menu.idCategorie,
                    }"
                    class="sub-menu-item"
                    >{{ menu.libelle }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="has-submenu parent-menu-item">
              <a>{{ $t("nav.autre.autre") }}</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">
                <li v-for="menu in Other" v-bind:key="menu.idCategorie">
                  <router-link
                    @click.native="ici"
                    :to="{
                      path:
                        `/${$i18n.locale}/article/categorie/` +
                        menu.idCategorie,
                    }"
                    class="sub-menu-item"
                    >{{ menu.libelle }}
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="has-submenu parent-menu-item">
              <a>{{ $t("nav.outils.outils") }}</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">
                <li v-for="menu in Nike" v-bind:key="menu.idCategorie">
                  <router-link
                    @click.native="ici"
                    :to="{
                      path:
                        `/${$i18n.locale}/article/categorie/` +
                        menu.idCategorie,
                    }"
                    class="sub-menu-item"
                    >{{ menu.libelle }}
                  </router-link>
                </li>
              </ul>
            </li>

            <!-- <li class="has-submenu parent-menu-item">
              <a>{{$t("shops.shop")}}</a>
              <span class="menu-arrow"></span>
              <ul class="submenu">
                <li>
                <router-link
                :to="{ path: `/${$i18n.locale}/article/promotions/` }"
                class="sub-menu-item"
                >{{ $t("shops.promotion") }}
                </router-link>
                </li>
                <li>
                <a
                href="https://oanke.com/fr/shopHome/S2A%20SPORT"
                class="sub-menu-item"
                >{{ $t("shops.shops") }}
                </a>
                </li>
              </ul>
            </li> -->
            <li>
              <router-link
                :to="{ path: `/${$i18n.locale}/article/promotions` }"
                class="sub-menu-item"
                >{{ $t("shops.promotion") }}
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ path: `/${$i18n.locale}/evenement/` }"
                class="sub-menu-item"
                >{{ $t("evenement.evenement") }}
              </router-link>
            </li>
            <li class="has-submenu parent-menu-item">
              <a>{{ lng }}</a
              ><span class="menu-arrow"></span>
              <ul class="submenu">
                <li>
                  <a @click="changedtofr" class="sub-menu-item">{{
                    $t("lang1")
                  }}</a>
                </li>
                <li>
                  <a @click="changedtoen" class="sub-menu-item">{{
                    $t("lang2")
                  }}</a>
                </li>
              </ul>
            </li>
            <!-- <li>
              <v-text-field
              v-model.lazy="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
              @keydown.enter="searchresult"
              class="search ms-2 sub-menu-item pb-2"
            >
            </v-text-field>
            </li> -->
          </ul>
          <!--end navigation menu-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../i18n";
export default {
  name: "Head",

  data: () => ({
    menu: {},
    current: 1,
    search: [],
  }),
  computed: {
    ...mapGetters({
      lng: "lng",
      s2a: "s2amenu",
      nike: "s2anikemenu",
      other: "s2aothermenu",
      articles: "s2aarticles",
      // searchs:"search"
    }),
    S2a() {
      if (this.s2a) {
        return this.s2a;
      } else {
        return "";
      }
    },
    Nike() {
      if (this.nike) {
        return this.nike;
      } else {
        return "";
      }
    },
    Other() {
      if (this.other) {
        return this.other;
      } else {
        return "";
      }
    },
    Id: function() {
      if (this.$route.params.id) {
        return this.$route.params.id;
      }
    },
  },
  mounted() {
    this.$store.dispatch("gets2amenu");
    this.$store.dispatch("gets2anikemenu");
    this.$store.dispatch("gets2aothermenu");
  },
  methods: {
  
    ici() {
      this.$store.dispatch("gets2aarticles", {
        id: this.Id,
        page: this.current,
      });
    },
    // searchresult() {
    //   if(this.search)
    //   {
    //    this.$store.dispatch("getsearch",{page: this.current, s: this.search})
    //   console.log(this.searchs.length > 0)
    //   console.log(this.searchs.length)
    //   console.log(this.searchs)
    //   if(this.searchs.length > 0)
    //   {
    //     this.$router.push(`/${i18n.locale}/article/search/`+this.searchs).catch(() => {});
    //   }
    //   }

    // },
    changedtofr: function() {
      i18n.locale = "fr";
      this.$store.commit("setlng", { lang: this.$t("lang1") });

      let language = this.$route.params.lang;
      if (language != "en") {
        let splitted = this.$route.path.split(`${language}`);
        console.log(splitted)
        let url = "/fr" ;
        this.$router.push(url);
      }
    },
    changedtoen: function() {
      i18n.locale = "en";
      this.$store.commit("setlng", { lang: this.$t("lang2") });

      let language = this.$route.params.lang;
      if (language != "fr") {
        let splitted = this.$route.path.split(`${language}`);
        console.log(splitted)
        let url = "/en" ;
        this.$router.push(url);
      }
    },
    toggleMenu: function() {
      document.getElementById("isToggle").classList.toggle("open");
      var isOpen = document.getElementById("navigation");
      if (isOpen.style.display === "block") {
        isOpen.style.display = "none";
      } else {
        isOpen.style.display = "block";
      }
    },
  },
};
</script>
<style scoped>
.search {
  width: 130px !important;
}
@media (max-width: 990px) {
  .search {
    width: 98% !important;
  }
  }
</style>
