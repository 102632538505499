<template>
  <main>
    <section class="jumbo">
      <div class="position-relative overflow-hidden text-center">
        <div class="col-md-5 p-lg-12 mx-auto ">
          <h1 v-if="cards[0]" class="display-5 fw-normal text-dark"></h1>
          <h1 v-else class="display-5 fw-normal text-dark"></h1>
        </div>
      </div>
    </section>
    <section class="ms-12 me-12">
      <v-row no-gutters>
        <v-col :cols="12">
          <v-card-text class="" tile outlined>
            <div class="row">
              <div
                class="col-12 col-md-4 col-sm-6 col-xs-6 text-center"
                v-for="img in cards"
                v-bind:key="img.albums.idArticle"
              >
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-card :elevation="hover ? 16 : 2" class="card-size">
                    <router-link
                      v-bind:to="{
                        path:
                          `/${$i18n.locale}/article/details/` +
                          img.albums.idArticle,
                      }"
                    >
                      <v-img
                      contain
                        class="black--text align-end"
                        height="450px"
                        :src="img.albums.photo_entete"
                      >
                        <div class=" align-top d-flex justify-content-end">
                          <template v-if="img.albums.enstock === 'En stock'">
                            <span class="mt-2 me-2 badge badge-pill colors">{{
                              img.albums.enstock
                            }}</span>
                          </template>
                          <template v-else>
                            <span class="mt-2 me-2 badge badge-pill colors2">{{
                              img.albums.enstock
                            }}</span>
                          </template>
                        </div>
                        <div class="align-top d-flex justify-content-end">
                          <template v-if="img.albums.promotion">
                            <span class="mt-2 me-2 badge badge-pill colors3"
                              >Promo -{{ img.albums.pourcentage }} %</span
                            >
                          </template>
                        </div>
                      </v-img>
                    </router-link>
                    <v-card-text class="text--primary text-center">
                        <v-card-title>{{
                          img.albums.designation
                        }}</v-card-title>
                      <div>
                        {{ $t("nike.prix") }} {{ img.albums.prix }} {{ img.albums.devise.libelle }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </div>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </section>
  </main>
</template>
<script>
export default {
  props: {
    cards: Array,
  },
};
</script>
<style scoped>
.jumbo {
  margin: 0 60px 0 60px;
}
.card-size
{
  width: 100%;
  height: 100%;
}
.colors {
  color: white;
  background-color: #048444;
}
.stock {
  padding-top: 50%;
}
.colors2 {
  color: white;
  background-color: #fb9f06;
}
.colors3 {
  color: #fbe3d1;
  background-color: #6a0000;
}
.colorrs {
  background-color: #061ee4;
}
.principal {
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
}
</style>
