<template>
  <main>
    <Head></Head>
    <section class="jumbo">
      <div class="position-relative overflow-hidden text-center">
        <div class="container pt-4 pb-2">
          <v-text-field
            class="search"
            v-model.lazy="sear"
            @click:append="searchresult"
            append-icon="mdi-magnify"
            single-line
            :label="$t('search')"
            hide-details
            @keydown.enter="searchresult"
          >
          </v-text-field>
        </div>
      </div>
    </section>
    <Search :cards="search"></Search>
    <Foot id="myFooter"  ref="myFooter"></Foot>
  </main>
</template>
<script>
import Head from "../components/Head.vue";
import Foot from "../components/Foot.vue";
import Search from "../components/Search.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseURL } from "../main";
export default {
  name: "search",
  components: {
    Head,
    Foot,
    Search,
  },

  data: () => {
    return {
      currentpage: 1,
      sear: [],
    };
  },
  computed: {
    ...mapGetters({
      searchs: "search",
    }),

    search() {
      if (this.searchs) {
        return this.searchs;
      } else {
        return "";
      }
    },
  },
  mounted() {
    console.log(this.sear == "");
    if (this.sear == "") {
      this.sear = this.$route.params.data;
      this.searchresult();
      // this.sear.push(this.$route.params.data)
    }
  },
  methods: {
    ...mapActions(["getsearch"]),

    searchresult() {
      if (this.sear) {
        if ((this.currentpage = 1)) {
          this.$store.dispatch("getsearch", {
            page: this.currentpage,
            s: this.sear,
          });
          this.scroll();
        } else if (this.currentpage > 1) {
          this.currentpage = 1;
          this.$store.dispatch("getsearch", {
            page: this.currentpage,
            s: this.sear,
          });
          this.scroll();
        }
      }
    },
    scroll() {
      window.onscroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - this.$refs.myFooter.$el.clientHeight) {
          this.currentpage = this.currentpage + 1;
          this.scrollinfini(this.currentpage, this.sear);
        }
      };
    },
    async scrollinfini(page, s) {
      await axios
        .get(baseURL + "/sport/s2a/articles/search/page/" + page + "/" + s)
        .then((response) => {
          if (response.data) {
            if (response.data.length > 0) {
              this.getentete(response.data);
              //  this.currentpage=1
            }
          }
        });
    },
    getentete(S2a) {
      for (let i = 0; i < S2a.length; i++) {
        const element = S2a[i];
        this.search.push(element);
      }
    },
  },
};
</script>

<style scoped>
.jumbo {
  padding-top: 115px;
  margin: 0 60px 0 60px;
}
.principal {
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
}
</style>
