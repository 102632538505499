import axios from "axios";
import { baseURL } from "../../main";
const state = {
  promotion:{},
};

const getters = {
  promotion: (state) => state.promotion,
};

const actions = {
  async getpromotion({ commit },page) {
    let response = await axios.get(
      baseURL + "/sport/s2a/articles/promotion/page/"+page
    );
    let data = response.data;
    commit("setpromotion",  data );
  },
  
};

const mutations = {
  setpromotion(state, promotion ) {
    state.promotion = promotion;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
