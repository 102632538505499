import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Evenement from '../views/Evenement.vue'
import Promotion from '../views/Promotions.vue'
import Articles from '../views/Articles.vue'
import Search from '../views/search.vue'
import Details from '../views/Details.vue'
import Detailsevenement from '../views/DetailsEvenement.vue'
import i18n from '../i18n'

import store from "../store";
Vue.use(VueRouter)
 
const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        component: Home,
      },
      {
        path:'article/search/:data',
        component:Search
      },
      {
        path:'article/details/:id',
        component: Details,
      },
      {
        path:'evenement/details/:id',
        component: Detailsevenement,
      },
      {
        path:'article/categorie/:id',
        component: Articles,
      },
      {
        path:'article/promotions/',
        component: Promotion
      },
      {
        path: 'evenement',
        component: Evenement,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  }
  
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next({ name: 'Login'});
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next({ name: 'PersonalDetails'});
      return;
    }
    next();
  } else {
    next();
  }
});



export default router
