<template>
  <main>
    <Head></Head>
    <section class="jumbo">
      <div class="bg-light">
        <div class="col-md-5 p-lg-12 mx-auto ">
          <h2>
            {{$t("promotion.promotion")}}
          </h2>
        </div>
      </div>
    </section>
    <section class="ms-12 me-12">
      <v-row no-gutters>
        <v-col :cols="12">
          <v-card-text class="" tile outlined>
            <div class="promo row">
              <div
                class="col-12 col-md-3 col-sm-6 col-xs-6 text-center"
                v-for="img in promote"
                v-bind:key="img.albums.idArticle"
              >
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-card :elevation="hover ? 16 : 2" class="card-size">
                    <router-link
                      v-bind:to="{
                        path:
                          `/${$i18n.locale}/article/details/` +
                          img.albums.idArticle,
                      }"
                    >
                      <v-img
                      contain
                        class="black--text align-end"
                        height="450px"
                        :src="img.albums.photo_entete"
                      >
                      <div class=" align-top d-flex justify-content-end">
                          <template v-if="img.albums.enstock === 'En stock'">
                            <span class="mt-2 me-2 badge badge-pill colors">{{
                              img.albums.enstock
                            }}</span>
                          </template>
                          <template v-else>
                            <span class="mt-2 me-2 badge badge-pill colors2">{{
                              img.albums.enstock
                            }}</span>
                          </template>
                        </div>
                        <div class="align-top d-flex justify-content-end">
                          <template v-if="img.albums.promotion">
                            <span 
                            v-if="isBefore(img.albums.dateFinPromo) && !isBefore(img.albums.dateDebutPromo)"
                            class="mt-2 me-2 badge badge-pill colors3"
                              >  - {{ img.albums.pourcentage }} %</span
                            >
                          </template>
                        </div>
                      </v-img>
                    </router-link>

                    <v-card-text class="text--primary text-center">
                        <v-card-title class="text-center">{{
                          img.albums.designation
                        }}</v-card-title>
                      <div>
                        {{ $t("nike.prix") }} {{ img.albums.prix }} {{ img.albums.devise.libelle }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </div>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </section>
    <Foot id="myFooter"  ref="myFooter"></Foot>
  </main>
</template>

<script>
import Head from "../components/Head.vue";
import Foot from "../components/Foot.vue";
import { mapGetters, mapActions } from "vuex";
import axios from 'axios';
import { baseURL } from '../main';
import { isBefore } from '../helper';
export default {
  name: "search",
  components: {
    Head,
    Foot,
  },

  data: () => {
    return {
      currentpage: 1,
    };
  },
  computed: {
    ...mapGetters(["promotion"]),

    promote() {
      if (this.promotion) {
        return this.promotion;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.$store.dispatch("getpromotion", this.currentpage);
    this.scroll()
  },

  methods: {
    ...mapActions(["getpromotion"]),
    isBefore(str) {
      if (str) {
        console.log(isBefore(str));
        return isBefore(str);
      } else {
        return false;
      }
    },
   scroll() {
    
      window.onscroll = () => {
        // console.log(document.body.promo);
    // console.log(window.innerHeight + window.scrollY);
    // console.log(this.$refs.myFooter.$el.clientHeight);
    // console.log(document.body.offsetHeight);
    // console.log(document.body.offsetHeigh);
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - this.$refs.myFooter.$el.clientHeight) {
          this.currentpage = this.currentpage + 1;
          this.scrollinfini(this.currentpage);
        }
      };
    },
    async scrollinfini(page) {
      await axios
        .get(baseURL + "/sport/s2a/articles/promotion/page/" + page)
        .then((response) => {
          if (response.data) {
            if (response.data.length > 0) {
              this.getentete(response.data);
              //  this.currentpage=1
            }
          }
        });
    },
    getentete(S2a) {
      for (let i = 0; i < S2a.length; i++) {
        const element = S2a[i];
        this.promote.push(element);
      }
    },
  },
};
</script>
<style scoped>
.jumbo {
  padding-top: 115px;
  margin: 0 60px 0 60px;
  text-align: center;
}
.jumbo h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #d9232d;
}
.card-size
{
  width: 100%;
  height: 100%;
}
.jumbo h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.jumbo h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #061ee4;
  bottom: 0;
  left: calc(50% - 20px);
}
.principal {
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
}
.colors {
  color: white;
  background-color: #048444;
}
.stock {
  padding-top: 50%;
}
.colors2 {
  color: white;
  background-color: #fb9f06;
}
.colors3 {
  color: #fbe3d1;
  background-color: #6a0000;
}
.colorrs {
  background-color: #061ee4;
}
</style>