<template>
  <div>
    <footer class="footer">
      <div class="container-sm">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer img-fluid">
              <img src="/lg.png" style="width: 250px;" alt="" />
            </a>
            <p class="mt-4 whites">{{ $t("footer.logodesc") }}.</p>
          </div>
          <!--end col-->

          <div
            class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
            style="width:450px"
          >
            <h5 class="text-light footer-head">{{ $t("footer.head1") }}</h5>
            <a
              style="color:white; text-decoration: underline !important;"
              href="https://www.facebook.com/profile.php?id=100064142645145"
              target="_blank"
              class="mt-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                class="bi bi-facebook "
                fill="white"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                />
              </svg>
              S2asport
            </a>
            <br />
            <div class="mt-4">
              <a
                style="color:white; text-decoration: underline !important;"
                href="https://instagram.com/s2asport?igshid=YmMyMTA2M2Y="
                class="mt-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  fill="white"
                  height="19"
                  class="bi bi-instagram "
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                  />
                </svg>
                S2asport
              </a>
            </div>

            <br />
            <a
              href="https://www.tiktok.com/@s2asport?_t=8UYbam3jE6H&_r=1"
              style="color:white ;text-decoration:underline !important"
              class="mt-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                fill="white"
                class="bi bi-tiktok "
                viewBox="0 0 16 16"
              >
                <path
                  d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"
                />
              </svg>
              S2asport
            </a>
          </div>

          <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">{{ $t("footer.head2") }}</h5>
            <ul
              class=" text-justify list-unstyled spaces whites footer-list mt-4"
              style="padding-left:0 !important, color:white"
            >
              <li>s2asporttogo@gmail.com</li>
              <li>(+228) 97 13 38 38</li>
              <li>(+228) 92 25 41 04</li>
            </ul>
          </div>
          <!--end col-->
          <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light text-center footer-head">
              {{ $t("footer.head3") }}
            </h5>
            <ul
              class="text-center list-unstyled spaces whites footer-list mt-4"
              style="padding-left:0 !important, color:white"
            >
              <li>
                Carrefour be kpota non loin de la mosque de be kpota (+228) 97
                13 38 38
              </li>
              <li>
                Carrefour atrokpokodji en face de la station oris a 100 m du
                Carrefour pk14 (+229) 57 11 58 26
              </li>
            </ul>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div>
            <div class="text-center">
              <p class="mb-0 whites">
                © 2019-{{ annee }} copyright S2A - {{ $t("allright") }} - by
                <a
                  style="color:white; text-decoration: underline !important;"
                  href="https://s2atechnology.com/"
                  >S2A Technology</a
                >
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
  </div>
</template>

<script>
export default {
  name: "Foot",

  data: () => {
    return {};
  },
  computed: {
    annee() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style>
.spaces {
  padding-left: -24px;
}
.let {
  padding-left: 20px;
}
.color {
  color: blue;
}
.whites {
  color: white;
}
.container-sm {
  max-width: 1500px !important;
}
</style>
