<template>
  <v-app>
    <div class="" id="">
      <!-- Navbar STart -->
      <Head>
      </Head>
      <!-- Navbar End -->
      <card :cards="card"></card>
      <Foot id="myFooter"  ref="myFooter"></Foot>
    </div>
  </v-app>
</template>
<script>
import Head from "../components/Head.vue";
import Foot from "../components/Foot.vue";
import Card from "../components/Card.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseURL } from "../main";
export default {
  name: "Details",
  components: {
    Head,
    Foot,
    Card,
  },
  data: () => {
    return {
      currentpage: 1,
    };
  },
  computed: {
    ...mapGetters(["s2aarticles"]),
    Id: function() {
      if (this.$route.params.id) {
        return this.$route.params.id;
      }
    },
    card() {
      if (this.s2aarticles) {
        return this.s2aarticles;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.$store.dispatch("gets2aarticles", {
      id: this.Id,
      page: this.currentpage,
    });
    this.scroll();
  },
  methods: {
    ...mapActions(["gets2aarticles"]),
    scroll() {
      window.onscroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - this.$refs.myFooter.$el.clientHeight) {
          this.currentpage = this.currentpage + 1;
          this.scrollinfini(this.Id, this.currentpage);
        }
      };
    },
    async scrollinfini(id, page) {
      await axios
        .get(baseURL + "/sport/s2a/articles/categorie/" + id + "/" + page)
        .then((response) => {
          if (response.data) {
            if (response.data.length > 0) {
              this.getentete(response.data);
              //  this.currentpage=1
            }
          }
        });
    },
    getentete(S2a) {
      for (let i = 0; i < S2a.length; i++) {
        const element = S2a[i];
        this.card.push(element);
      }
    },
  },
};
</script>
