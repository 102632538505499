<template>
<main>

    <Head></Head>
    <section class="jumbo container">
          <div class="bg-light">
          <div class="col-md-5  p-lg-12 mx-auto ">
            <h6>
                {{details.contenu}}
            </h6>
          </div>
        </div>
      </section>
    <div class="container">
         <div class="row"> 
        <div>
          <v-card-text class="" tile outlined>
            <div class="row">
              <div
                class="col-12 col-md-4 col-sm-12 col-xs-6 text-center"
                v-for="img in details.pieces"
                v-bind:key="img.id"
              >
                <v-hover v-slot:default="{ hover }" open-delay="200">
                  <v-card :elevation="hover ? 16 : 2">
                  <a class="glightbox" data-gallery="images-gallery"
                        :href="img.url">
                    <img
                      class="white--text align-end"
                      width="350px"
                      :src="img.url"
                    >
                    </a>
                    
                  </v-card>
                </v-hover>
              </div>
            </div>
          </v-card-text>
        </div>
      </div>
      </div>
    <Foot></Foot>
</main>
</template>
<script>
import Head from "../components/Head.vue"
import Foot from "../components/Foot.vue"
import { mapActions, mapGetters } from "vuex"
// import Head from "../../components/Head.vue"
export default
{
    name:"Detailsevenement",
    components:
    {
        Head,
        Foot
    },
    data: ()=>
    {
        return{

        }
    },
    computed:
    {
        ...mapGetters(["oneevenement"]),
        Id: function()
        {
            if(this.$route.params.id)
            {
                return this.$route.params.id
            }
        },
        details()
        {
            if( this.oneevenement)
            {
                return this.oneevenement
            }
            else
            {
                return ""
            }
        }
    },
    mounted()
    {
      
        this.$store.dispatch("getoneevenement", this.Id)
    },
    methods:
    {
        ...mapActions(["getoneevenement"])
    }
}
</script>
<style scoped>

.font
{
      font-family: 'Cinzel', serif;
}
.jumbo {
  text-align: center;
  padding-top: 115px;
  /* margin: 0 60px 0 60px; */
}
.jumbo h6 {
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-weight: 500;
  position: relative;
  color: #d9232d;
}
.jumbo h6::before {
  content: "";
  position: absolute;
  display: block;
  width: 100px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.jumbo h6::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #061ee4;
  bottom: 0;
  left: calc(50% - 20px);
}
</style>